import React, { useEffect, useState } from "react"
import {Link, useNavigate} from 'react-router-dom'
import { DownOutlined, InsuranceOutlined } from '@ant-design/icons';

import { useTranslation } from "react-i18next";
import "./Header.css";
import axios from "axios";
import { Button, Dropdown, Input, Space, Spin,message } from "antd";
import i18n from "../../../i18n";
import * as CONSTANTS from '../../../CONSTANTS';
import useToken from "../../useToken";

function Header({setLanguage ,setUserData , userData ,phoneP, phoneD = '' , email=''}){
const [userName, setUserName] = useState();
const {t} = useTranslation('home');
const [isLoaded, setDataLoaded] = useState(false);
const [profilePic, setProfilePic] = useState(null);
const navigate = useNavigate();
const [type, setType] = useState('normal');
const { token, setToken } = useToken();
const [numberOfAddedPatients, setNumberOfAddedPatients] = useState(0);



const handelLogout = ()=>{
	setToken(null);
	window.location = '/';
}


		useEffect(() => {
			setDataLoaded(true);
			const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+token
              }
			const user = JSON.parse(userData);
			console.log(user);
			if(user){

        if(user.data !== null){
			let email = user.email;

			setUserName(user.profiles[0].firstname+' '+(user.profiles[0].lastname != null ? user.profiles[0].lastname  : ''));
			setType(user.type);
			setProfilePic(user.profiles[0].profilePicture?.url);
			setDataLoaded(false);



			axios.get(CONSTANTS.DEFAULT_URL+`profiles?pagination[page]=1&pagination[pageSize]=100&populate[user][role]=*&filters[user][role]=4&filters[user][sales_man_email]=${email}` , {
				headers: headers
			  }).then(function (appointmentData){
		setNumberOfAddedPatients(appointmentData.data.meta.pagination.total);
					
		
		
				
		
			})
			.catch(function (error) {
				console.log(error);
		
			})





		}else{
			setDataLoaded(false);

		}
	}else{
		setDataLoaded(false);

	}


	}, []);
  return (
    <>
	
	<Spin spinning={isLoaded}>
	<header class="header">
	<nav className="navbar navbar-expand-lg navbar-light bg-light">
				<div className="container">

					<div class="navbar-header">
						<a id="mobile_btn" href="javascript:void(0);">
							<span class="bar-icon bar-icon-one">
								<span></span>
								<span></span>
								<span></span>
							</span>
						</a>
						<Link to={"/"} class="navbar-brand logo">
							<img src="/assets/img/Logo&Slogan (Colored).png" class="img-fluid" alt="Logo" />
						</Link>
					</div>
					<div class="main-menu-wrapper">
						<div class="menu-header">
							<Link to={"/"} class="menu-logo">
								<img src="/assets/img/Logo&Slogan (Colored).png" class="img-fluid" alt="Logo" />
							</Link>
							<a id="menu_close" class="menu-close" href="javascript:void(0);">
								<i class="fas fa-times"></i>
							</a>
						</div>
						{token != null &&  (
						<ul class="main-nav">
						
							<li>
							<Link to="/mysales" className="btn text-primary">مبيعاتي</Link>

							</li>
					
							<li>
							   <span>رقم الطلاب المضافين {numberOfAddedPatients}</span>

							</li>

							
						</ul>		
						)
} 
					</div>		 
					<ul class="nav header-navbar-rht">
		
					
						
		
						<li class="nav-item">
			
		 
		  			{token != null && (
						<>
							<li class="nav-item dropdown has-arrow logged-item">
								<a href="#" class="dropdown-toggle nav-link" data-bs-toggle="dropdown">
									<span class="user-img">
									<img width="31" class="rounded-circle" src={(profilePic != null ? (profilePic) : ('/assets/img/patients/patient.jpg'))}  alt={userName} />

									</span>
								</a>
								<div class="dropdown-menu dropdown-menu-end">
									<div class="user-header">
										<div class="avatar avatar-sm">
										<img  class="avatar-img rounded-circle" src={(profilePic != null ? (profilePic) : ('/assets/img/patients/patient.jpg'))}  alt={userName} />

										</div>
										<div class="user-text">
											<h6>{userName}</h6>
											<p class="text-muted mb-0">{type}</p>
										</div>
									</div>
									
							

									<Link class="dropdown-item" to={'/profile'}>{t('profile')}</Link>
								
								
									<a class="dropdown-item" href="#" onClick={handelLogout}>{t('logout')}</a>
								</div>
							</li>
						
							</>
							

          )}

						</li>
						<li>
			
						</li>
					
					</ul>
					</div>
				</nav>
				
			</header>
			</Spin>
    </>
  )
}
export default Header