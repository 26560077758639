import * as CONSTANTS from './CONSTANTS';


export const  check_auth = async (token)=>{

          try {

            let response = await  fetch(CONSTANTS.DEFAULT_URL+'users/me', {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json' ,
                'Authorization': 'Bearer '+token

              },
            })
            let json = await response.json();

            if(json.type === 'patient' && ["sales@doxx.care","sales2@doxx.care","sales3@doxx.care","sales4@doxx.care","orman@doxx.care",
            "orman2@doxx.care","orman3@doxx.care","orman4@doxx.care","orman5@doxx.care"
            ,"orman6@doxx.care","orman7@doxx.care","orman8@doxx.care","orman9@doxx.care","orman10@doxx.care","maybel-orman@doxx.care","sales-orman@doxx.care"].includes(json.email)){
              return { success: true, data: json };

            }else{
   
             localStorage.removeItem('token');
              return { success: false, data: null };

            }
        
            
        
        } catch (error) {
              localStorage.removeItem('token');

              return { success: false };
          }
}

export const  getUserLocation = async (token)=>{
  
    try {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
              const userLocation = {            // ADDED
                lat: position.coords.latitude,  // ADDED
                lng: position.coords.longitude, // ADDED
              };         
              return userLocation;     
              // ADDED
            });
          } else {
            // code for legacy browsers
            return false;
          }
     
  
  
      
  
  } catch (error) {
      return false;
    }
}