import axios from 'axios';
import React, { Suspense, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import * as CONSTANTS from '../../CONSTANTS'
import { Button, message, Pagination, Skeleton, Select, Input, Row, Col, Flex, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import 'antd/dist/antd.css'
import { LoadingOutlined } from '@ant-design/icons/LoadingOutlined';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { updateRateLimits } from '@sentry/utils';

const yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);
 // GitHub Gist ID and Personal Access Token
 const gistId = 'ce3339cad78034d0d8a766f430e1d779';  // Replace with your Gist ID
 const gitToken = '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';  // Replace with your GitHub token
 
 const gistApiUrl = `https://api.github.com/gists/${gistId}`;
 

let timeout;
let currentValue;

const fetch2 = (value, callback, token) => {
    if (timeout) {
        clearTimeout(timeout);
        timeout = null;
    }
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
    };
    currentValue = value;
    const fake = () => {

        axios.get(CONSTANTS.DEFAULT_URL + `profiles?&filters[$or][0][$or][0][firstname][$containsi]=${value}&filters[$or][0][$or][1][lastname][$containsi]=${value}
    &filters[$or][0][$or][2][user][mobileNumber][$containsi]=${value}&filters[$or][0][$or][3][user][email][$containsi]=${value}&filters[$or][1][$and][0][firstname][$containsi]=${value}
    &filters[$or][1][$and][1][lastname][$containsi]=&populate[profilePicture]=*&populate[patient]=*&populate[user][role]=*&populate[user][id]=true&sort=[user][createdAt]:desc&filters[user][role]=4`,

            {
                headers: headers
            })
            //   .then((response) => response.json())
            .then((d) => {
                if (currentValue === value) {
                    console.log(d);
                    const data = d.data;

                    const data2 = data.data.map((item) => ({

                        value: item.attributes?.user?.data?.id,
                        text: item.attributes.firstname + ' ' + item.attributes.lastname,
                    }));
                    console.log(data2);
                    callback(data2);
                }
            });
    };
    if (value) {
        timeout = setTimeout(fake, 300);
    } else {
        callback([]);
    }
};
const getPlans = () => {


    return axios.get(CONSTANTS.DEFAULT_URL + `supscription-plans`)


};

export default function AddPatientToPlan({ token }) {
    const tableRef = useRef(null);
    const [key, setKey] = useState(1);
    var params = useParams();
    const { t } = useTranslation('home');
    const [load, setLoading] = useState(true);
    const [appointmentsLoad, setAppointmentsLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [page, setCurrent] = useState(1);
    const [searchWord, setSearchWord] = useState(null);
    const [otp, setOtp] = useState(null);
    const [otpReady, setOtpReady] = useState(false);
    const [csvData, setCsvData] = useState(null);
    const [patientPhoneNumber, setPatientPhoneNumber] = useState('+20');
    const [orman, setOrman] = useState(false);
    const [fatherPhoneNumber, setFatherPhoneNumber] = useState('+20');
    const [fatherName, setfatherName] = useState('');
    const [patientFirstName, setPatientFirstName] = useState('');
    const [patientLastName, setPatientLastName] = useState('');
    const [selectedPatientId, setSelectedPatientId] = useState();
    const [ormanPatients, setOrmanPatients] = useState([]);

    const [plans, setPlans] = useState([]);
    const [loading, setLoadings] = useState(false);
    const [profilePic, setProfilePic] = useState(null);
    const [appointments, setAppointmentsData] = useState(null);
    const [uploadId, setUploadId] = useState(false);

    const [imageUrl, setImageUrl] = useState();
    const [progress, setProgress] = useState(0);
    const [name, setName] = useState('');
    const [branch, setBranch] = useState();
    const [studentClass, setStudentClass] = useState();
    const [students, setStudents] = useState([]);
     // Load existing CSV data from the Gist

     function getOrmanPatients(value, callback){        
       if (timeout) {
        clearTimeout(timeout);
        timeout = null;
    }
    const headers = {
        'Content-Type': 'application/,json',
        'Authorization': 'Bearer ' + token
    };
    currentValue = value;
    const fake = () => {

        axios.get(CONSTANTS.DEFAULT_URL + `profiles?&filters[$or][0][$or][0][firstname][$containsi]=${value}&filters[$or][0][$or][1][lastname][$containsi]=${value}
    &filters[$or][0][$or][2][user][mobileNumber][$containsi]=${value}&filters[$or][0][$or][3][user][email][$containsi]=${value}&filters[$or][1][$and][0][firstname][$containsi]=${value}
    &filters[$or][1][$and][1][lastname][$containsi]=&populate[user][supscription_plan]=null&filters[$and][0][createdAt][$gt]=${yesterday.toISOString()}&populate[profilePicture]=*&populate[patient]=*&populate[user][role]=*&populate[user][id]=true&sort=[user][createdAt]:desc&filters[user][role]=4`,

            {
                headers: headers
            })
            //   .then((response) => response.json())
            .then((d) => {
                if (currentValue === value) {
                    console.log(d);
                    const data = d.data;
                    setStudents(data);
                    const data2 = data.data.map((item) => ({
                        value: item.attributes?.user?.data?.id,
                        text: item.attributes.firstname + ' ' + item.attributes.lastname,
                    }));
                    console.log(data2);
                    callback(data2);
                }
            });
    };
    if (value) {
        timeout = setTimeout(fake, 300);
    } else {
        callback([]);
    }
     }

     function searchOnStudents(newValue){
            getOrmanPatients(newValue, setOrmanPatients);
     }

     function setSelectedStudents(newValue){
        setSelectedPatientId(newValue)
     }

     

 useEffect(() => {
    const fetchGist = async () => {
      try {
        const response = await axios.get(gistApiUrl);
        const file = response.data.files['data.csv']; // Assuming the file is named 'data.csv'
        setCsvData(file.content);
      } catch (error) {
        console.error('Error fetching Gist:', error);
      }
    };
    fetchGist();
  }, []);
    function validateEgyptianPhoneNumber(phoneNumber) {
        // Remove any non-digit characters from the input
        const cleanPhoneNumber = phoneNumber.replace(/\D/g, '');

        // Define the regex pattern for Egyptian phone numbers
        const egyptianPhonePattern = /^(?:\+?20)?(?:10|11|12|15|16|17|18|19)\d{8}$/;

        // Test if the cleaned number matches the pattern
        return egyptianPhonePattern.test(cleanPhoneNumber);
    }

    function isNotEmail(email) {
        // Regular expression to match a valid email address.
        const emailRegex = /^[a-zA-Z0-9.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]+$/;

        // Return true if the string does not match the regular expression.
        return !emailRegex.test(email);
    }
    const sendOtp = () => {


        // if(isNotEmail(name) ){
        //     message.error("EMail Not Right")
        //     return false;
        // }
        if (!validateEgyptianPhoneNumber(patientPhoneNumber)) {
            message.error("please enter valid phone number")

            return false;
        }
        let data = JSON.stringify({
            orman: orman,
            patientPhoneNumber: patientPhoneNumber,

        });
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        };
        axios.post(CONSTANTS.DEFAULT_URL + 'sendOtp', data
            , {
                headers: headers
            })
            .then(function (response) {
                if (response.status === 200) {
                    message.success("sent Successfully");
                    setOtpReady(true);
                } else {
                    message.error("Can't sent");

                }

            })
            .catch(function (error) {
                console.log(error);

                // navigate('/login');

                // setLoading(true);

                if (error.response.status === 403) {
                    navigate('/login');


                }
                message.error(error.response.data.error.message)

            })
    }

    // Load the CSV file from the public folder
    const loadCsv = async () => {
        const response = await fetch('/data.csv'); // Replace with your actual file path in /public
        const text = await response.text();
        setCsvData(text);
    };

    const isEmpty = (value) => (
        value === undefined ||
        value === null ||
        (typeof value === "object" && Object.keys(value).length === 0) ||
        (typeof value === "string" && value.trim().length === 0)
    )
    const addPlan = async () => {

        if (orman) {
            if (isEmpty(fatherName) === true) {
                message.error("Please Enter Father Name");
                return;
            }

            if (isEmpty(fatherPhoneNumber) === true) {
                message.error("Please Enter Father Phone Number");
                return;
            }

            if (isEmpty(studentClass) === true) {
                message.error("Please Enter Student class");
                return;
            }

            if (isEmpty(branch) === true) {
                message.error("Please Enter Branch");
                return;
            }

        }

        if (newPatient) {
            if (isEmpty(patientFirstName) === true) {
                message.error("Please Enter Patient first Name");
                return;
            }
            if (isEmpty(patientLastName) === true) {
                message.error("Please Enter Patient Last Name");
                return;
            }

            if (isEmpty(patientPhoneNumber) === true) {
                message.error("Please Enter  Phone Number");
                return;
            }

        }

      
if(newPatient == true){
    let data2 = JSON.stringify({
        mobileNumber: `${patientPhoneNumber}`,
        otp: otp,
        father_name: fatherName,
        chronic: chronic,
        father_number: fatherPhoneNumber,
        patientFirstName,
        patientLastName,
        patientPhoneNumber,
        newPatient,
    });
    const headers2 = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
    };
 await   axios.post(CONSTANTS.DEFAULT_URL + 'supscription-plan/createPatient', data2
        , {
            headers: headers2
        })
        .then(async function (response) {
            let pa = null;
            const newRow = `${patientFirstName},${patientLastName},${patientPhoneNumber},${fatherName},${fatherPhoneNumber},${branch},${studentClass}\n`;

            // Append the new row to the existing CSV data
            const updatedCsv = csvData + '\n' + newRow;
    
            await axios.patch(
                gistApiUrl,
                {
                  files: {
                    'data.csv': {
                      content: updatedCsv,
                    },
                  },
                },
                {
                  headers: {
                    Authorization: `token ${gitToken}`,
                  },
                }
              );
              setCsvData(updatedCsv);
    
            if (response.status === 200) {
                pa =  response.data.patient;


            } else {
                message.error("Can't update");
                return false;

            }

            let data = JSON.stringify({
                "plan": favorite_plan,
                orman: orman,
                patientPhoneNumber: `${patientPhoneNumber}`,
                otp: otp,
                father_name: fatherName,
                chronic: chronic,
                father_number: fatherPhoneNumber,
                patientFirstName,
                patientLastName,
                patientPhoneNumber,
                newPatient,
                'patient': pa
            });
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            };
            axios.post(CONSTANTS.DEFAULT_URL + 'add_plan_from_support', data
                , {
                    headers: headers
                })
                .then(function (response) {
                    if (response.status === 200) {
                        message.success("Upadted Successfully");
                    } else {
                        message.error("Can't update");
    
                    }
    
                })
                .catch(function (error) {
                    console.log(error);
    
                    // navigate('/login');
    
                    // setLoading(true);
    
                    if (error.response.status === 403) {
                        navigate('/login');
    
    
                    }
                    message.error(error.response.data.error.message)
    
                })

        })
        .catch(function (error) {
            console.log('errrrrrrrrrrrr',error);

            // navigate('/login');

            // setLoading(true);

            // if (error && error.response.status === 403) {
            //     navigate('/login');


            // }

            message.error(error.response.data.error.message);
            return false;


        })
}else{

    let data = JSON.stringify({
        "plan": favorite_plan,
        orman: orman,
        patientPhoneNumber: patientPhoneNumber,
        otp: otp,
        father_name: fatherName,
        chronic: chronic,
        father_number: fatherPhoneNumber,
        patientFirstName,
        patientLastName,
        patientPhoneNumber,
        newPatient,
        'patient': favorite_patients
    });
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
    };
    axios.post(CONSTANTS.DEFAULT_URL + 'add_plan_from_support', data
        , {
            headers: headers
        })
        .then(function (response) {
            if (response.status === 200) {
                message.success("Upadted Successfully");
            } else {
                message.error("Can't update");

            }

        })
        .catch(function (error) {
            console.log(error);

            // navigate('/login');

            // setLoading(true);

            if (error.response.status === 403) {
                navigate('/login');


            }
            message.error(error.response.data.error.message)

        })
}
    
    }

    function addOrmanPlan(){
        const patient = students?.data.find(item => item.attributes.patient?.data?.id === selectedPatientId);
        console.log(patient);

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        };
        let data = JSON.stringify({
            "plan": 12,
            orman: false,
            patientPhoneNumber: `${patient?.attributes?.user?.data?.attributes?.mobileNumber}`,
            otp: otp,
            father_name: patient?.attributes.user.data.attributes.father_name,
            chronic: patient?.attributes?.user?.data?.attributes?.chronic,
            father_number: patient?.attributes.user.data.attributes.father_number,
            patientFirstName: patient?.attributes?.firstname,
            patientLastName: patient?.attributes?.lastname,
            patientPhoneNumber: patient?.attributes?.user?.data?.attributes?.mobileNumber,
            newPatient: true,
            'patient': selectedPatientId
        });
        axios.post(CONSTANTS.DEFAULT_URL + 'add_plan_from_support', data
        , {
            headers: headers
        })
        .then(function (response) {
            if (response.status === 200) {
                message.success("Upadted Successfully");
            } else {
                message.error("Can't update");

            }

        })
        .catch(function (error) {
            console.log(error);

            // navigate('/login');

            // setLoading(true);

            if (error.response.status === 403) {
                navigate('/login');


            }
            message.error(error.response.data.error.message)

        })
    }

    const navigate = useNavigate();
    useEffect(() => {
        setLoading(true);
        if (token != null) {
            setLoading(true);
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
            axios.get(CONSTANTS.DEFAULT_URL + 'users/me', {
                headers: headers
            })
                .then(async function (response) {
                    let json = response.data;
                    if (json.email === "orman@doxx.care" || json.email === "orman2@doxx.care"
                        || json.email === "orman3@doxx.care" || json.email === "orman4@doxx.care" || json.email === "orman5@doxx.care" ||
                        json.email === "orman6@doxx.care" || json.email === "orman7@doxx.care" || json.email === "orman8@doxx.care" ||
                        json.email === "orman9@doxx.care" || json.email === "orman10@doxx.care" ||  json.email === "maybel-orman@doxx.care" ||  json.email === "sales-orman@doxx.care") {
                        setOrman(true);

                    } else {
                        setOtpReady(true);
                        setOrman(false);

                    }

                    var query = '';
                    await getPlans().then((response) => {
                        setPlans(response.data.data);
                    })
                        .catch((error) => { setPlans([]) });
                    setLoading(false);

                })
                .catch(function (error) {
                    navigate('/login');

                    setLoading(true);

                    if (error.response.status === 403) {
                        navigate('/login');


                    }
                    console.log(error);
                })

        } else {
            navigate('/login');

            setLoading(true);

        }

    }, [page]);

    const [data, setData] = useState([]);
    const [favorite_plan, setFavorite_plan] = useState();
    const [chronic, setChronic] = useState(false);
    const [newPatient, setNewPatient] = useState(true);

    const [favorite_plan_name, setfavorite_plan_name] = useState();

    const handleSearch3 = (newValue) => {
        fetch2(newValue, setData2, token);
    };


    const { Option } = Select;
    const handleSearch = (newValue) => {
        fetch(setData());
    };

    const [data2, setData2] = useState([]);
    const [favorite_patients, setFavorite_patients] = useState();
    function deleteLetters(event) {
        const inputField = event.target;
        const inputValue = inputField.value;

        // Remove non-numeric characters using a regular expression
        const sanitizedValue = inputValue.replace(/[^0-9]/g, ''); // Keeps only numeric characters

        // Update the input field value with the sanitized value
        inputField.value = sanitizedValue;
        setOtp(sanitizedValue);
    }
    const handleChange3 = (newValue) => {
        setFavorite_patients(newValue);

    };
    const handleChange = (newValue) => {
        setFavorite_plan(newValue);


    };
    const onChange = (e) => {
        setChronic(e.target.checked);
        console.log(`checked = ${e.target.checked}`);
    };
    const onChange2 = (e) => {
        setNewPatient(e.target.checked);
        console.log(`checked = ${e.target.checked}`);
    };
    return (



        <Suspense>

            {load === false ? (<><div class="breadcrumb-bar">
                <div class="container-fluid">
                    <div class="row align-items-center">
                        <div class="col-md-12 col-12">
                            <nav aria-label="breadcrumb" class="page-breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="/">الرئيسية </a></li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
                <div class="content">
                    <div class="container-fluid">
                        <div class="row">


                            <div class="col-md-12">

                                <div class="tab-pane fade show active">
                                    <div class="card card-table mb-0">


                                        <Card title="اضافة خطة للمستخدم" style={{ width: 1000 }}>
                                            <Checkbox style={{ "color": "green" }} onChange={onChange2} checked={newPatient}>طالب جديد</Checkbox><br></br><br></br>
                                            {newPatient || orman ? (<>     <Row className="mt-3">
                                                        <Col span={6}>
                                                            <label> رقم هاتف الطالب</label>
                                                        </Col>
                                                        <Col span={12}>  <Input type="tel"
                                                            className="col-md-4"
                                                            value={patientPhoneNumber} name="" id="" placeholder="أدخل رقم هاتف الطالب ..." onChange={e => { if(e.target.value !== '' && e.target.value !== '+2' && e.target.value !== '+'){setPatientPhoneNumber(e.target.value)} }} />
                                                        </Col>
                                                       

                                            </Row>
                                            <Row className="mt-3">
                                            <Col span={6}>
                                                    <label>الاسم الاول للطالب</label>
                                                </Col>
                                                <Col span={12}>  <Input type="text"
                                                    className="col-md-4"
                                                    value={patientFirstName} name="" id="" placeholder="أدخل الاسم الاول للطالب" onChange={e => { setPatientFirstName(e.target.value) }} />
                                                </Col>
                                            </Row>
                                            <Row className="mt-3">
                                            <Col span={6}>
                                                    <label>الاسم الاخير للطالب</label>
                                                </Col>
                                                <Col span={12}>  <Input type="text"
                                                    className="col-md-4"
                                                    value={patientLastName} name="" id="" placeholder="إدخل الاسم الاخير للطالب" onChange={e => { setPatientLastName(e.target.value) }} />
                                                </Col>
                                            </Row>
                                            
                                            </>) : (<>  <label>البحث عن الطالب</label>
                                                        <Select
                                                            showSearch
                                                            value={favorite_patients}
                                                            style={{ width: '400px' }}
                                                            placeholder={''}
                                                            defaultActiveFirstOption={false}
                                                            showArrow={false}
                                                            filterOption={false}
                                                            onSearch={handleSearch3}

                                                            onChange={handleChange3}
                                                            notFoundContent={null}
                                                            tokenSeparators={[',']}

                                                            options={(data2 || []).map((d) => ({
                                                                value: d.value,
                                                                label: d.text,
                                                            }))}
                                                        /></>)}
                                            <Row className="mt-3">
                                                <Col span={6}>
                                                    <label>رقم هاتف أب الطالب</label>
                                                </Col>
                                                <Col span={12}>  <Input type="tel"
                                                    className="col-md-4"
                                                    value={fatherPhoneNumber} name="" id="" placeholder="أدخل رقم هاتف أب الطالب .." onChange={e => { if(e.target.value !== '' && e.target.value !== '+2' && e.target.value !== '+'){ setFatherPhoneNumber(e.target.value) }} }/>
                                                </Col>
                                            </Row>
                                            <Row className="mt-3">
                                            <Col span={6}>
                                                    <label> اسم والد الطالب</label>
                                                </Col>
                                                <Col span={12}>  <Input type="text"
                                                    className="col-md-4"
                                                    value={fatherName} name="" id="" placeholder="أدخل أسم والد الطالب" onChange={e => { setfatherName(e.target.value) }} />
                                                </Col>
                                            </Row>
                                            <Row className="mt-3">
                                            <Col span={6}>
                                                    <label>فرع المدرسة</label>
                                                </Col>
                                                <Col span={12}>  <Input type="text"
                                                    className="col-md-4"
                                                    value={branch} name="" id="" placeholder="ادخل فرع المدرسة" onChange={e => { setBranch(e.target.value) }} />
                                                </Col>
                                            </Row>
                                            <Row className="mt-3">
                                            <Col span={6}>
                                                    <label>الفصل</label>
                                                </Col>
                                                <Col span={12}>  <Input type="text"
                                                    className="col-md-4"
                                                    value={studentClass} name="" id="" placeholder="ادخل اسم الفصل" onChange={e => { setStudentClass(e.target.value) }} />
                                                </Col>
                                            </Row>

                                            {
                                                orman && !newPatient ? (
                                                    <Row className='mt-3'>
                                                        <Col span={6}>
                                                        <label>البحث عن الطالب</label>
                                                        </Col>
                                                        <Col span={12}>
                                                        <Select
                                                            showSearch
                                                            value={selectedPatientId}
                                                            style={{ width: '400px' }}
                                                            placeholder={''}
                                                            defaultActiveFirstOption={false}
                                                            showArrow={false}
                                                            filterOption={false}
                                                            onSearch={searchOnStudents}

                                                            onChange={setSelectedStudents}
                                                            notFoundContent={null}
                                                            tokenSeparators={[',']}

                                                            options={(ormanPatients || []).map((d) => ({
                                                                value: d.value,
                                                                label: d.text,
                                                            }))}
                                                        />
                                                        <Button type='primary' className='btn btn-success' onClick={() => { addOrmanPlan() }}>إضافة خطة الاورمان للطالب</Button>
                                                        </Col>
                                                       
                                                        </Row>
                                                        ) : ''
                                            }
                                            {orman && !newPatient ? (
                                                <>

                                                    <Row className="mt-3">
                                                        <Col span={6}>
                                                            <label> رقم هاتف الطالب</label>
                                                        </Col>
                                                        <Col span={12}>  <Input type="tel"
                                                            className="col-md-4"
                                                            value={patientPhoneNumber} name="" id="" placeholder="أدخل رقم هاتف الطالب .." onChange={e => { if(e.target.value !== '' && e.target.value !== '+2' && e.target.value !== '+'){setPatientPhoneNumber(e.target.value)} }} />
                                                        </Col>
                                                        <Col span={4}>
                                                            <Button type='primary' className='btn btn-success' onClick={() => { sendOtp() }}>أرسل الرمز التأكيدي</Button>
                                                        </Col>
                                                    </Row>

                                                    {otpReady ? (<>    <div class="form-group col-6">
                                                        <label>رمز التأكيد</label>

                                                        <Input type="tel" style={{ width: '400px' }}
                                                            className="col-md-4"
                                                            value={otp} name="" id="" placeholder="رجاء ادخال رمز التأكيد" onChange={e => { deleteLetters(e); setOtp(e.target.value) }} />
                                                    </div></>) : (<></>)}


                                                </>) : (<div class="form-group col-12"><br></br>

                                                    

                                                </div>
                                            )}



                                            {orman === false ? (<>      <div className="form-group col-6">
                                                <label>الخطة المختارة</label>

                                                {!load ? (
                                                    <Select
                                                        showSearch
                                                        placeholder=''
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.value.toLowerCase().includes(input.toLowerCase())
                                                        }
                                                        value={favorite_plan_name}
                                                        onChange={(e, title) => { setfavorite_plan_name(title.value); setFavorite_plan(title.title) }}
                                                        className="col-md-4"
                                                        style={{ minWidth: 200 }}
                                                    >
                                                        {plans.map((c, i) => {
                                                            return (
                                                                <Option value={c.attributes.name} title={c.id} key={i}>
                                                                    <>                  {c.attributes.name}
                                                                    </>

                                                                </Option>
                                                            );
                                                        })}                  </Select>



                                                ) : (
                                                    <></>
                                                )}
                                            </div></>) : (<></>)}


                                            <div className="form-group">

                                                <Checkbox onChange={onChange}>مرض مزمن</Checkbox>
                                            </div>
                                            <div className="form-group">

                                                {otpReady || newPatient ? (<Button type='primary' className='btn btn-success' onClick={() => { addPlan() }}>إضافة</Button>) : (<></>)}

                                            </div>

                                        </Card>
                                    </div>
                                </div>


                            </div>

                        </div>
                    </div>

                </div></>) : (<Skeleton active />)}

        </Suspense>

    )
}
